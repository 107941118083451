import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Link as RouterLink, useLoaderData } from "react-router-dom";

export default function Order() {
  const { order } = useLoaderData();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Card>
          <CardMedia
            sx={{ height: 240 }}
            image={order.QRCode}
            title={order.title}
          />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Card sx={{height: 240}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {order.title}
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Explain how the ordering works - if you've got whatsapp you scan the QR code and the item goes through directly
              to the business whatsapp account. Or I guess we need to allow email or something.
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              {order.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              component={RouterLink}
              to={`/order/${order.id}`}
            >
              Order Now
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
