import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Chip, Container, Grid, Stack } from "@mui/material";
import { Link as RouterLink, useLoaderData } from "react-router-dom";
import { Box } from "@material-ui/core";

export default function Detail() {
  const { detail } = useLoaderData();
  const chips = detail.tags.map((tag, index) => (
    <Chip key={index} variant="filled" label={tag} />
  ));
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Card>
            <CardMedia
              sx={{ height: 440 }}
              image={detail.image}
              title={detail.title}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card>
            <CardContent sx={{ height: 390 }}>
              <Typography gutterBottom variant="h5" component="div">
                {detail.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {detail.description}
              </Typography>
              <Stack
                paddingTop={1}
                direction="row"
                spacing={1}
                alignItems={"left"}
              >
                {chips}
              </Stack>
            </CardContent>
            <CardActions sx={{ float: "bottom", height: 50 }}>
              <Button
                size="small"
                component={RouterLink}
                to={`/order/${detail.id}`}
              >
                Order Now
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
