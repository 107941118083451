import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Container from "@mui/material/Container";

import { BottomNav, TopAppBar } from "./Components";

import { router } from "./Routes";

export default function App() {
  return (
      <Container maxWidth="lg">
        <TopAppBar />
        <RouterProvider router={router} />
        <BottomNav />
      </Container>
  );
}
