import * as React from "react";
import Instagram from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

export default function BottomNav() {
  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          component="a"
          href="https://instagram.com/chiffon-cake-name"
          target="_blank"
        >
          <Instagram />
        </IconButton>
        <Button size="small" component="a" href="/about/">
          <Typography variant="h7" component="div">
            About
          </Typography>
        </Button>
        <Button size="small" component="a" href="/disclaimer/">
          <Typography variant="h7" component="div">
            Disclaimer
          </Typography>
        </Button>
        <Button size="small" component="a" href="/privacy-notice/">
          <Typography variant="h7" component="div">
            Privacy
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
