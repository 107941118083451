export default function getCakes(id) {
  const cakes = [
    {
      id: "1",
      title: "Chocolate",
      image: "http://live.staticflickr.com/184/464889897_091b7b5e5e_c.jpg",
      shortDescription: "Chocolate short description goes here.",
      description:
        "This is chocolate, it's got choco-late in it, it's real good you should eat it. Mom says chocolate isn't good for dogs.",
      tags: ["vegan", "gluten-free"],
    },
    {
      id: "2",
      title: "Vanilla",
      image:
        "https://c1.wallpaperflare.com/preview/237/1003/655/homemade-chiffon-cake.jpg",
      shortDescription: "Vanilla short description goes here.",
      description:
        "This is vanilla, personally I find vanilla a bit boring but ja 'real' vanilla is quite a distinct flavour, especially if you get the beans and all that.",
      tags: ["vegan", "sugar-free", "gluten-free"],
    },
    {
      id: "3",
      title: "Orange",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/1/13/Blood_Orange_Chiffon_Cake_with_Mango_Curd_%28Unsplash%29.jpg",
      shortDescription: "Orange short description goes here.",
      description:
        "Orange! It's got Vitamin C, presumably, probably not actually that's been cooked out to nothing by now. But it's one of my favourite flavours.",
      tags: ["dairy-free", "egg-free"],
    },
    {
      id: "4",
      title: "Granadilla Glazed Chiffon",
      image:
        "https://bakesbybrownsugar.com/wp-content/uploads/2023/03/Blood-Orange-Pound-Cake-15.jpg",
      shortDescription: "Another short description goes here.",
      description:
        "Granadilla with a glaze. Ja so the granadilla is quite nice, you can use it as a vine plant, run it up some trellis along a wall, quite nice yeah.",
      tags: ["vegan", "freezer-friendly"],
    },
    {
      id: "5",
      title: "Chocolate with Caramel pour-over",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/c/c5/Joann%27s_cake%2C_Now_featuring_every_delicious_thing_at_once_-_Flickr_-_ginnerobot.jpg",
      shortDescription: "Something brief to describe the thing goes here.",
      description:
        "Caramel pour-over, delicious pour-over. Just as coffee pour-over is a bit on the fancy hipster side, basically same story here. So ja it should impress your friends. Sort of a DIY component to the thing. De-constructed if you will.",
      tags: ["egg-free", "refined-sugar-free"],
    },
    {
      id: "6",
      title: "Apple",
      image: "https://live.staticflickr.com/65535/48945868738_885b6483b7_b.jpg",
      shortDescription: "This is the last one I ran out of ideas",
      description:
        "Apple flavour. Who's hungry for Apples! Jerry sure was with his attempted marketing pitch to the NPCs in that one episode. Apples are good they keep doctors away apparently.",
      tags: ["dairy-free", "vegan", "egg-free", "gluten-free"],
    },
  ];

  if (id) {
    return cakes.find((x) => x.id === id);
  } else {
    return cakes;
  }
}

export function gridLoader() {
  const grid = getCakes();
  return { grid };
}

export function detailLoader({ params }) {
  const detail = getCakes(params.id);
  return { detail };
}

export function orderLoader({ params }) {
  const cake = getCakes(params.id);
  const order = {
    QRCode:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/256px-QR_code_for_mobile_English_Wikipedia.svg.png",
    ...cake,
  };
  return { order };
}
