import { createTheme } from '@mui/material/styles';

export const themeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#a9316d',
      },
      secondary: {
        main: '#a931a9',
      },
      background: {
        default: '#eeeeee',
        paper: '#f5f5f5',
      },
    },
    spacing: 8,
  };

const theme = createTheme(themeOptions);

export default theme;