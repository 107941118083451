import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
  import { About, Detail, Home, LegalDisclaimer, Order, Privacy } from "./Pages/";
  import { gridLoader, detailLoader, orderLoader } from './Data';

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      loader: gridLoader,
    },
    {
      path: "detail/:id",
      element: <Detail />,
      loader: ({ params }) => {
        return detailLoader({params});
      },
    },
    {
      path: "order/:id",
      element: <Order />,
      loader: ({ params }) => {
        return orderLoader({params});
      },
    },
    {
        path: "about",
        element: <About />
    },
    {
        path: "privacy-notice",
        element: <Privacy />
    },
    {
        path: "disclaimer",
        element: <LegalDisclaimer />
    }
  ]);

  export { router };