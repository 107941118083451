import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

export default function LogoWithBlurb() {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2} paddingTop={2} paddingBottom={2}>
        <Typography variant="h4" component="div">
          This is some info about this bakery it is a bit of text that is kind
          of long
        </Typography>
        <Typography variant="h4" component="div">
          Here is some more text and info about the cakes and the philosophy I
          suppose
        </Typography>
      </Stack>
    </Box>
  );
}
