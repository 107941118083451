import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Link as RouterLink } from "react-router-dom";
import { Chip, Stack } from "@mui/material";

export default function MediaCard({ media }) {
  const chips = media.tags.map((tag, index) => (
    <Chip key={index} variant="filled" label={tag} />
  ));

  return (
    <Card>
      <CardMedia sx={{ height: 240 }} image={media.image} title={media.title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {media.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {media.shortDescription}
        </Typography>
        <Stack paddingTop={1} direction="row" spacing={1} alignItems={"left"}>
          {chips}
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" component={RouterLink} to={`/detail/${media.id}`}>
          Order
        </Button>
      </CardActions>
    </Card>
  );
}
