import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MediaCard from "../Components/MediaCard";
import { useLoaderData } from "react-router-dom";
import { LogoWithBlurb } from "../Components";

export default function Home() {
  const { grid } = useLoaderData();
  const gridItems = grid.map((gridItem) => (
    <Grid key={gridItem.id} item xs={12} sm={6} md={4}>
      <MediaCard media={gridItem}></MediaCard>
    </Grid>
  ));

  return (
    <div>
      <LogoWithBlurb />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {gridItems}
        </Grid>
      </Box>
    </div>
  );
}
